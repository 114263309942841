import { Component, Inject} from '@angular/core';
import {LOCAL_STORAGE, WebStorageService} from 'angular-webstorage-service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  constructor(@Inject(LOCAL_STORAGE) private storage: WebStorageService,
  private router: Router)
  {
  /*  if (this.storage.get('nom_user') === null) {
      console.log("ddbizarre")
      this.router.navigate(['login']);



    } else
    {
      console.log("bizarre")
      this.router.navigate(['Dashboard']);
     // this.appareilService.recents_ajouts();
    }*/
  }

}
