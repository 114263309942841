import { Component, OnInit, Input, Inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AngularFirestore } from '@angular/fire/firestore';
import {LOCAL_STORAGE, WebStorageService} from 'angular-webstorage-service';
import { RouterOutlet, Router } from '@angular/router';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  @Input() name;
  login_user: any = '';
  login_mdp: any = '';
  data_login: any = [];
  data_storage: any = [];
  constructor(@Inject(LOCAL_STORAGE) private storage: WebStorageService,
  private router: Router, private db: AngularFirestore, private toastr: ToastrService) { }

  ngOnInit() {
  }
  connexiond()
  {
    //alert(this.login_user)
    
    if ((this.login_user === '') || (this.login_mdp === '')) {

      this.toastr.warning(
        // tslint:disable-next-line: max-line-length
        '<span data-notify="icon" class="nc-icon nc-bell-55"></span><span data-notify="message"> CHAMP(S)  VIDE(S) !</span>',
          "",
          {
            timeOut: 4000,
            closeButton: true,
            enableHtml: true,
            toastClass: 'alert alert-warning alert-with-icon',
            positionClass: 'toast-top-left'
          }
        );


    } else {

      this.db
      .collection('users', res =>{
         return res.where('login_ut', '==', this.login_user)
                   .where('mdp_ut', '==', this.login_mdp)
      })
      .valueChanges().subscribe( data1 => {
          this.data_login = data1;
          if (this.data_login.length === 0) {
            this.toastr.warning(
              // tslint:disable-next-line: max-line-length
              '<span data-notify="icon" class="nc-icon nc-bell-55"></span><span data-notify="message"> IDENTIFIANTS INCORRECTS !</span>',
                "",
                {
                  timeOut: 4000,
                  closeButton: true,
                  enableHtml: true,
                  toastClass: 'alert alert-warning alert-with-icon',
                  positionClass: 'toast-top-left'
                }
              );
              


          } else {

            
    this.toastr.info(
      // tslint:disable-next-line: max-line-length
      '<span data-notify="icon" class="nc-icon nc-bell-55"></span><span data-notify="message">CONNEXION - SUCCES !.</span>',
        "",
        {
          timeOut: 4000,
          closeButton: true,
          enableHtml: true,
          toastClass: 'alert alert-info alert-with-icon',
          positionClass: 'toast-top-left'
        }
      );

            this.storage.set('nom_user', this.data_login[0].nom_user);
            this.storage.set('prenom_user', this.data_login[0].prenom_user);
            this.router.navigate(['/dashboard']);


          }

      });

    }
  }
}
